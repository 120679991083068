import type ModelCollectionGame  from "./model/ModelCollectionGame"
import type Game  from "./model/Types/Game"


   /*
    lists consists:

    gamesJoinableAll: Array<Game>
    gamesJoinableMy: Array<Game>
    gamesJoinableOther: Array<Game>
        
    gamesActiveAll: Array<Game>
    gamesActiveMy: Array<Game>
    gamesActiveOther: Array<Game>

    gamesRevengeMy: Array<Game>
    gamesRevengeForMe: Array<Game>

    как пользоваться: 

    проверяешь gamesActiveMy - если есть открывешь игру 

    проверяешь gamesJoinableMy - если есть открывешь окно ожидания подключения к нами созданной игре    
                                 если нет то закрываешь это окно 
    проверяешь gamesRevengeMy - если есть открывешь окно ожидания подключения к нами созданному реваншу    
                                если нет то закрываешь это окно 
    проверяешь gamesRevengeForMe - если есть открывешь окно приглашения играть в реванш от другого игрока
                                если нет то закрываешь это окно 

    выводишь списко gamesJoinableOther - в окно списка игр 
    выводишь countJoinable  число - в Navbar в иконке игр (или можно просто gamesJoinableOther.length)
    выводишь gamesActiveAll - в окне идщие игры (gamesActiveAll а не gamesActiveOther - т.к. мы хотим видеть и свои игры тоже )
    */

export default class ArraySortedGame {
    gamesAll:ModelCollectionGame

    gamesJoinableAll: Array<Game>
    gamesJoinableMy: Array<Game>
    gamesJoinableOther: Array<Game>
        
    gamesActiveAll: Array<Game>
    gamesActiveMy: Array<Game>
    gamesActiveOther: Array<Game>

    gamesRevengeMy: Array<Game>
    gamesRevengeForMe: Array<Game>

    countJoinable:number
    countActiveOther:number

    constructor(games:ModelCollectionGame) {
        this.gamesAll = games;
        this.gamesJoinableAll = [];
        this.gamesJoinableMy  = [];
        this.gamesJoinableOther  = [];

        this.gamesActiveAll = [];
        this.gamesActiveMy = [];
        this.gamesActiveOther = [];

        this.gamesRevengeMy  = [];
        this.gamesRevengeForMe  = [];
        this.countJoinable = 0;
        this.countActiveOther = 0;
    }


    clear() {
        this.gamesJoinableAll = [];
        this.gamesJoinableMy  = [];
        this.gamesJoinableOther  = [];

        this.gamesActiveAll = [];
        this.gamesActiveMy = [];
        this.gamesActiveOther = [];

        this.gamesRevengeMy  = [];
        this.gamesRevengeForMe  = [];

        this.countJoinable = 0;
        this.countActiveOther = 0;
    }


    

    update(games:ModelCollectionGame, playerUuid:string) {
        this.clear();        
        games.forEachAsync( (game) => {        
            let isMy = game.isPlayerIn(playerUuid);
            let isCreatorMe = game.isPlayerCreator(playerUuid);

            if(!isMy && game.isTypeTraining() ) // skip tournaments and trainings
                return;

            if(game.isStatusJoinable()) {
                this.gamesJoinableAll.push(game);
                let collection = (isMy) ? this.gamesJoinableMy : this.gamesJoinableOther                
                collection.push(game);
            }

            if(game.isStatusInPlay()) {
                this.gamesActiveAll.push(game);
                let collection = (isMy) ? this.gamesActiveMy : this.gamesActiveOther;      
                collection.push(game);
            }

            if(game.isStatusRevengeWaitPlayer() && isMy) {                
                let collection = (isCreatorMe) ? this.gamesRevengeMy : this.gamesRevengeForMe
                collection.push(game);
            }
        });

        //sort active game other by created date
        this.gamesActiveOther.sort((gameA:Game, gameB:Game):number => {
            return gameA.created_timestamp - gameB.created_timestamp
        })

        this.countJoinable = this.gamesJoinableOther.length;

        this.countActiveOther = this.gamesActiveOther.length;
    }     



    printout( log: (...args:Array<any>)=>void ) {
        //log("gamesActiveAll");    this.printGameCollection(log, this.gamesActiveAll);
        log("gamesActiveMy");     this.printGameCollection(log, this.gamesActiveMy);
        log("gamesJoinableMy");     this.printGameCollection(log, this.gamesJoinableMy);

        log("gamesRevengeMy");     this.printGameCollection(log, this.gamesRevengeMy);
        log("gamesRevengeForMe");  this.printGameCollection(log, this.gamesRevengeForMe);

        log("gamesJoinableOther");  this.printGameCollection(log, this.gamesJoinableOther);
        log("gamesActiveOther");  this.printGameCollection(log, this.gamesActiveOther);                                                    
    }

    printGameCollection( log: (...args:Array<any>)=>void , gameCollection:Array<Game>) {
        gameCollection.forEach( (game) => {
            this.printGame(log, game);
        });        
    }

    printGame( log: (...args:Array<any>) => void, game:Game) {
        log("Game "+game.uuid+" status = "+game.status);
    }

}
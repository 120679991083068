import { render, staticRenderFns } from "./mobileMenu.vue?vue&type=template&id=004d5cec&"
import script from "./mobileMenu.vue?vue&type=script&lang=ts&"
export * from "./mobileMenu.vue?vue&type=script&lang=ts&"
import style0 from "./mobileMenu.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Background: require('/artifacts/BGFrontend/components/Layout/Background.vue').default,Interface: require('/artifacts/BGFrontend/components/Layout/Interface.vue').default,AppAdvicer: require('/artifacts/BGFrontend/components/Layout/AppAdvicer.vue').default,Dialogs: require('/artifacts/BGFrontend/components/Dialogs.vue').default,Loading: require('/artifacts/BGFrontend/components/Layout/Loading.vue').default,ActivatorFullScreen: require('/artifacts/BGFrontend/components/Activator/ActivatorFullScreen.vue').default,AspectRatioBox: require('/artifacts/BGFrontend/components/Layout/AspectRatioBox.vue').default,Preloader: require('/artifacts/BGFrontend/components/UiCustom/Preloader.vue').default})

































let loadingIsDone = false;

if(window) {
    window.addEventListener('load', () => {
        loadingIsDone = true;
        console.log("Loading finished default");
    });
}


const ISDEV = (process.env.NODE_ENV !== 'production')

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'

@Component({
    middleware: 'game-listener',
    name: 'mobile-menu',    
    computed: {},
})
export default class mobileMenuLayout extends Vue {

    allowHideLoad:boolean = false
    on_facebook: boolean = process.env.FACEBOOK_INSTANT_GAME == '1'
    dev: boolean = ISDEV 

    created() {                
        if(!window)
            return;
                   
        window.addEventListener('load', () => {
            console.log("Loading finished normal");
            loadingIsDone = true;
            this.loadFinish();
        });

        this.$nextTick( ()=>{
            if(loadingIsDone) {
                console.log("Loading already finished");
                this.loadFinish();
            } else {
                (<any>this).$wait.start('windowLoad'); 
                //allow hide by click after 15 seconds
                // in case some thing wrong
                setTimeout(()=>this.allowHideLoad=true , 15000)                  
            }                                                
        })                        
        this.$store.commit("SET_GAME_LOAD", false);
    }

    loadFinish() {
        (<any>this).$wait.end('windowLoad');
        this.$store.dispatch("loadFinish");
    }

    onLoadClick() {
        if(!this.allowHideLoad)
            return;

        this.loadFinish();
    }  
    
    get loaded() {
        return !(<any>this).$wait.is('windowLoad');
    }

    showGameInfo(gameUuid:string) {
        
    }

    showPlayerInfo(playerUuid:string) {

    }
}

